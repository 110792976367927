import svgIcons from './svgIcons';

const icons: { icon: string }[] = [
  { icon: 'AddChartIcon' },
  { icon: 'DoneAllIcon' },
  { icon: 'FactCheckIcon' },
  { icon: 'PermIdentityIcon' },
  { icon: 'AnaliseDeCrescimento' },
  { icon: 'CategoriaCliente' },
  { icon: 'ControleDeCompras' },
  { icon: 'CurvaABC' },
  { icon: 'Desconto' },
  { icon: 'Fidelizacao' },
  { icon: 'FluxoDeVendas' },
  { icon: 'IndicadoresDeEficiencia' },
  { icon: 'MargemDeLucro' },
  { icon: 'MetasIndividuais' },
  { icon: 'MetasGerais' },
  { icon: 'PanoramaDeCadastros' },
  { icon: 'PanoramaDeVendas' },
  { icon: 'PoderDeEconomia' },
  { icon: 'Premiacao' },
  { icon: 'Rupturas' },
  { icon: 'CrescimentoResumido' },
  { icon: 'IconeCategoriaCliente' },
  { icon: 'RetratoDasVendas' },
  { icon: 'VendasDetalhadas' }
];
export { icons, svgIcons };
