import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { READ, DASHBOARD } from 'src/utils/rbac/tablePermissionsNames';
import ComponentsByRequiredPrivilegesJSX from 'src/utils/rbac/ComponentsByRequiredPrivilegesJSX';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import { RootState } from '../redux/types';
import type { Theme } from '../theme';
import type { Dashboard, Emp } from '../@types/emp';

interface SetEmpProp {
  (dash: Dashboard): boolean;
}
interface SetDialogProp {
  (isOpen: boolean): void;
}

interface DashSelectionProps {
  className?: string;
  dialogState: boolean;
  handleDash: SetEmpProp;
  setDialogState: SetDialogProp;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  selectDash: {
    padding: theme.spacing(4)
  }
}));

const DashSelection: FC<DashSelectionProps> = ({
  className,
  handleDash,
  setDialogState,
  dialogState
}: DashSelectionProps) => {
  const classes = useStyles();

  const { isAdmin, loading, myEmp } = useSelector(
    (state: RootState) => ({
      isAdmin: state.account.user.isAdmin,
      loading: state.admin.loading,
      myEmp: state.account.myEmp as Emp
    }),
    shallowEqual
  );
  const [dashValue, setDashValue] = useState<Dashboard | string>('');

  const handleChange = ({ target }: any): void => {
    const dashData: Dashboard = target.value;
    setDashValue(dashData);
  };

  const handleSelectionDash = (value: Dashboard): void => {
    handleDash(value);
  };
  return (
    <>
      <ComponentsByRequiredPrivilegesJSX module={DASHBOARD} privileges={[READ]}>
        <Dialog
          className={classes.root}
          open={dialogState}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={(): void => setDialogState(false)}
          aria-labelledby="select-empresa"
          aria-describedby="alert-select-empresa-description"
        >
          <DialogTitle id="select-empresa">
            Selecione um dashboard para visualiza-lo:
          </DialogTitle>
          <DialogContent className={classes.selectDash}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="emp-label">Dashboard</InputLabel>
              <Select
                fullWidth
                labelId="emp-label"
                id="loja"
                required
                value={dashValue}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>...</em>
                </MenuItem>
                {myEmp.dashboards?.map(
                  (emp: any): ReactNode => {
                    return (
                      <MenuItem key={emp.id} value={emp}>
                        {emp.name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={(): void => setDialogState(false)} color="primary">
              Cancelar
            </Button>
            {dashValue !== null &&
              dashValue !== '' &&
              typeof dashValue !== 'string' && (
                <Button
                  onClick={(): void => handleSelectionDash(dashValue)}
                  autoFocus
                  disabled={loading}
                >
                  {loading && <CircularProgress size={14} />} Selecionar
                </Button>
              )}
          </DialogActions>
        </Dialog>
      </ComponentsByRequiredPrivilegesJSX>
    </>
  );
};

export default DashSelection;
