/* eslint-disable react/react-in-jsx-scope */
// constroi as rotas de acordo com o match
// entre o papel do usuário logado e o array
// de papeis informado na propriedade allowedRoutes
// de cada uma das rotas cadastradas

import { useState, FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Emp } from 'src/@types/emp';
import { RootState } from 'src/redux/types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { PAPEL1, PAPEL2, PAPELDEFAULT } from 'src/utils/rbac/papeis';

interface RoutesPropsData extends RouteProps {
  allowedRoles?: string[];
}

const RoutesByRole: FC<RoutesPropsData> = ({ allowedRoles, ...rest }: any) => {
  const { myEmp, myPrivilege } = useSelector(
    (state: RootState) => ({
      myEmp: state.account.myEmp,
      myPrivilege: String(state.account.user?.privilegeHash || PAPELDEFAULT)
    }),
    shallowEqual
  );
  const [empData, setEmpData] = useState<Emp>(myEmp); // dados da empresa
  const history = useHistory();
  const [hasPermission, setHasPermission] = useState(true);

  useEffect(() => {
    setEmpData(myEmp);
    if (Array.isArray(allowedRoles)) {
      const permission = allowedRoles.includes(myPrivilege);
      setHasPermission(permission);
    }

    // Redireciona para outra página quando a empresa está Inativa
    if (
      empData?.status === 'Inativa' &&
      (myPrivilege === PAPEL1 || myPrivilege === PAPEL2)
    ) {
      history.push('/empresa-inativa');
    }
  }, [myPrivilege, empData, myEmp, history]);

  if (!hasPermission) {
    return <Redirect to="/nao-autorizado" />;
  }

  return <Route {...rest} />;
};

export default RoutesByRole;
