import {
  LIST_COLABORADOR_INIT,
  LIST_COLABORADOR_SUCCESS,
  CREATE_COLABORADOR_INIT,
  CREATE_COLABORADOR_SUCCESS,
  EDIT_COLABORADOR_INIT,
  EDIT_COLABORADOR_SUCCESS,
  CLEAR_COLABORADORES,
  COLABORADORES_ERRO_MSG
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  colaboradores: [],
  isUpdated: false,
  isCreated: false,
  isError: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_COLABORADOR_INIT: {
      return {
        ...state,
        loading: true,
        isCreated: false,
        isUpdated: false
      };
    }
    case LIST_COLABORADOR_SUCCESS: {
      const { colaboradores } = action.payload;
      return {
        ...state,
        colaboradores,
        loading: false,
        isError: false
      };
    }
    case CREATE_COLABORADOR_INIT: {
      return {
        ...state,
        loading: true,
        isCreated: false,
        isFailed: false
      };
    }
    case CREATE_COLABORADOR_SUCCESS: {
      const { user, msg } = action.payload;
      return {
        ...state,
        colaboradores: state.colaboradores.concat(user),
        loading: false,
        isCreated: true,
        isFailed: false,
        msg
      };
    }
    case EDIT_COLABORADOR_INIT: {
      return {
        ...state,
        isUpdated: false
      };
    }
    case EDIT_COLABORADOR_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        isUpdated: true,
        msg
      };
    }
    case COLABORADORES_ERRO_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case CLEAR_COLABORADORES: {
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isError: false,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};
