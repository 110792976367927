import { LOG_ERROR_MSG, SEND_LOG } from '../actionTypes';

export const sendLog = (data) => ({
  type: SEND_LOG,
  payload: data
});

export const LogErroMsg = (data) => ({
  type: LOG_ERROR_MSG,
  payload: data
});
