import {
  CREATE_FATOR_INIT,
  CREATE_FATOR_SUCCESS,
  FATOR_ERROR_MSG,
  CLEAR_FATOR,
  UPDATE_FATOR_INIT,
  UPDATE_FATOR_SUCCESS,
  FATOR_LIST_FATORES_EMPRESA_INIT,
  FATOR_LIST_FATORES_EMPRESA_SUCCESS,
  FATOR_OBTEM_FATOR_SUCCESS,
  FATOR_OBTEM_FATOR_INIT
} from '../actionTypes';

export const createFatorInit = (data) => ({
  type: CREATE_FATOR_INIT,
  payload: data
});

export const createFatorSucess = ({ current, msg }) => {
  return {
    type: CREATE_FATOR_SUCCESS,
    payload: { current, msg }
  };
};

export const clearFator = () => ({
  type: CLEAR_FATOR,
  payload: {}
});

export const updateFatorInit = (data) => ({
  type: UPDATE_FATOR_INIT,
  payload: data
});

export const updateFatorSuccess = ({ msg }) => {
  return {
    type: UPDATE_FATOR_SUCCESS,
    payload: { msg }
  };
};

export const obtemFatorInit = (data) => ({
  type: FATOR_OBTEM_FATOR_INIT,
  payload: data
});

export const obtemFatorSuccess = (data) => ({
  type: FATOR_OBTEM_FATOR_SUCCESS,
  payload: data
});

export const obtemListaFatoresEmpresaInit = (data) => ({
  type: FATOR_LIST_FATORES_EMPRESA_INIT,
  payload: data
});

export const obtemListaFatoresEmpresaSuccess = (data) => ({
  type: FATOR_LIST_FATORES_EMPRESA_SUCCESS,
  payload: data
});

export const fatorErrorMsg = (data) => ({
  type: FATOR_ERROR_MSG,
  payload: data
});
