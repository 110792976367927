
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  IconButton,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Avatar
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useAuth from '../../hooks/useAuth';
import { HomeOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 200
  },
  accountLabel: {
    color: theme.palette.primary.black
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.primary.main
  },
  iconStyle: {
    marginRight: theme.spacing(2)
  },
  itemName: {
    paddingBottom: 0
  },
  itemPrivilege: {
    paddingTop: 0
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const { userPrivileges } = useSelector(
    (state) => ({
      userPrivileges: state.account.userPrivileges
    }),
    shallowEqual
  );

  const getInitials = (name) => {
    if (!name) return '';
    const initials = name.split(' ').map(n => n[0]).join('').slice(0, 2);
    return initials.toUpperCase();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Hidden smDown>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
        >
          {' '}
          <Avatar className={classes.avatar}>
            <AccountCircleIcon />
          </Avatar>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            aria-label="Minha Conta"
            component={RouterLink}
            to="/conta"
          >
            <Typography variant="h6" color="primary">
              {user.name}
            </Typography>
            <AccountCircleIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Minha Conta"
            onClick={handleLogout}
          >
            <Typography variant="h6" color="primary">
              SAIR
            </Typography>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Hidden>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem disabled divider className={classes.itemName}>
          <Typography variant="body1" noWrap>
            <strong>{user.name}</strong>
          </Typography>
        </MenuItem>
        <MenuItem component={RouterLink} to="/conta">
        <HomeOutlined className={classes.iconStyle} />
         Conta
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon className={classes.iconStyle} /> Sair
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
