import {
  CREATE_CAMPANHA_INIT,
  CREATE_CAMPANHA_SUCCESS,
  CAMPANHA_ERROR_MSG,
  CLEAR_CAMPANHA,
  UPDATE_CAMPANHA_INIT,
  UPDATE_CAMPANHA_SUCCESS,
  DUPLICATE_CAMPANHA_INIT,
  DUPLICATE_CAMPANHA_SUCCESS,
  CAMPANHA_LIST_CAMPANHA_INIT,
  CAMPANHA_LIST_CAMPANHA_SUCCESS,
  CAMPANHA_DELETE_CAMPANHA_INIT,
  CAMPANHA_DELETE_CAMPANHA_SUCCESS,
  CAMPANHA_OBTEM_CAMPANHA_INIT,
  CAMPANHA_OBTEM_CAMPANHA_SUCCESS,
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  campanhas: [],
  campanha: {},
  isUpdated: false,
  isDuplicated: false,
  isCreated: false,
  isDeleted: false,
  isRecived: false,
  isError: false,
  current: null,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAMPANHA_LIST_CAMPANHA_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case CAMPANHA_LIST_CAMPANHA_SUCCESS: {
      const { campanhas } = action.payload;
      return {
        ...state,
        campanhas,
        loading: false,
        isError: false
      };
    }
    case CAMPANHA_OBTEM_CAMPANHA_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case CAMPANHA_OBTEM_CAMPANHA_SUCCESS: {
      const { campanha } = action.payload;
      return {
        ...state,
        campanha: campanha,
        loading: false,
        isRecived: true,
        isError: false
      };
    }
    case CREATE_CAMPANHA_INIT:
      return { ...state, loading: true, isCreated: false };
    case CREATE_CAMPANHA_SUCCESS: {
      const { current, msg } = action.payload;
      return { ...state, current, isCreated: true, loading: false, msg };
    }
    case UPDATE_CAMPANHA_INIT:
      return { ...state, loading: false, isUpdated: false };
    case UPDATE_CAMPANHA_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isUpdated: true, loading: false, msg };
    }
    case DUPLICATE_CAMPANHA_INIT:
      return { ...state, loading: false, isDuplicated: false };
    case DUPLICATE_CAMPANHA_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isDuplicated: true, loading: false, msg };
    }
    case CAMPANHA_DELETE_CAMPANHA_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case CAMPANHA_DELETE_CAMPANHA_SUCCESS: {
      const { id, msg } = action.payload;
      return {
        ...state,
        campanhas: state.campanhas.filter((elem) => elem.id !== id),
        loading: false,
        isDeleted: true,
        msg
      };
    }
    case CAMPANHA_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case CLEAR_CAMPANHA: {
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isDuplicated: false,
        isDeleted: false,
        isRecived: false,
        isError: false,
        current: null,
        loading: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
