/* eslint-disable prefer-template */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FC, ReactNode } from 'react';
import type { User } from '../@types/user';
import SplashScreen from '../components/SplashScreen';
import firebase from '../lib/firebase';
import { loginJwtRealm } from '../api/mongoRealm';
import {
  authStateChanged,
  userDataRequestInit,
  clearUserAuth,
  adminClearAll,
  empresaCleanListEmps
} from '../redux/actions';
import { RootState } from '../redux/types';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  sendPasswordResetEmail: (email: string) => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({
  children
}: AuthProviderProps) => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.account);
  const signInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail = async (email: string): Promise<any> => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const handleAuth = async (user: firebase.User): Promise<void> => {
    try {
      const result = await user.getIdTokenResult(true);
      const token: string = await user.getIdToken(true);
      const mongoUser = await loginJwtRealm(token);
      const { empresa, privilegeHash } = result.claims;

      dispatch(
        userDataRequestInit({
          mongoUser,
          empId: empresa,
          privilegeHash
        })
      );
    } catch {
      dispatch(
        authStateChanged({
          isAuthenticated: false,
          loading: false,
          user: null
        })
      );
    }
  };

  const logout = (): Promise<void> => {
    dispatch(adminClearAll());
    dispatch(clearUserAuth());
    dispatch(empresaCleanListEmps());
    return firebase.auth().signOut();
  };
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        handleAuth(user);
      } else {
        dispatch(
          authStateChanged({
            isAuthenticated: false,
            user: null,
            loading: false
          })
        );
      }
    });

    return unsubscribe;
  }, []);

  if (!account.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...account,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        sendPasswordResetEmail,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
