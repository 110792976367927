// <ComponentsByRequiredPrivilegesJSX
//   module="metas"
//   privileges={["read", "update"]}
// >
//     <Children/>
// </>

// Recebe duas propriedades especificando o
// módulo a que se referem os componentes filhos
// e os privilégios requeridos para a renderização
// dos mesmos EX: module="metas" privileges={["read", "update"]}

// Retorna os componentes filhos caso o usuário
// logado tenha os privilégios requeridos

// Retorna null caso o usuário logado não possua
// os privilégios requeridos
// ocultando o componente filho

import { isNil } from 'lodash';
import { useState, FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/redux/types';

interface ComponentsByRequiredPrivilegesProps {
  module: string;
  privileges: string[];
  children: React.ReactNode;
}

const ComponentsByRequiredPrivilegesJSX: FC<ComponentsByRequiredPrivilegesProps> = ({
  module,
  privileges,
  children
}: any) => {
  const { userPrivileges } = useSelector(
    (state: RootState) => ({
      userPrivileges: state.account.userPrivileges
    }),
    shallowEqual
  );
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    let permission = true;
    privileges.forEach((privilege: string) => {
      if (!isNil(userPrivileges[module])) {
        permission &&= userPrivileges[module][privilege];
      } else {
        permission = false;
      }
    });
    setHasPermission(permission);
  }, []);

  // eslint-disable-next-line no-nested-ternary, react/react-in-jsx-scope
  // return <>{hasPermission ? children : null}</>;
  if (hasPermission) {
    return children;
  }
  if (!hasPermission) {
    return null;
  }
};

export default ComponentsByRequiredPrivilegesJSX;
