import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { IMeta } from '../modelos/IMeta';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ OBTER META POR ID ------------------------ //
const obtem = async (
    adminMongoDB: RealmUser,
    id: string
  ): Promise<IRespostaFuncao<IMeta>> => {
    return adminMongoDB.functions['empresas/lojas/metas/obtemMeta']({ id })
      .then((resultado) => resultado as IRespostaFuncao<IMeta>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao obter dados da Meta',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ CRIAR META ------------------------ //
const cria = async (
    adminMongoDB: RealmUser,
    meta: IMeta
  ): Promise<IRespostaFuncao<IMeta>> => {
    return adminMongoDB.functions['empresas/lojas/metas/criaMeta'](meta)
      .then((resultado) => resultado as IRespostaFuncao<IMeta>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar Meta',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ EDITAR META ------------------------ //
const atualiza = async (
    adminMongoDB: RealmUser,
    meta: IMeta
  ): Promise<IRespostaFuncao<IMeta>> => {
    return adminMongoDB.functions['empresas/lojas/metas/atualizaMeta'](meta)
      .then((resultado) => resultado as IRespostaFuncao<IMeta>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao editar Meta',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ DELETAR META ------------------------ //
  const deleta = async (
    adminMongoDB: RealmUser,
    id: string
  ): Promise<IRespostaFuncao<IMeta>> => {
    return adminMongoDB.functions['empresas/lojas/metas/removeMeta']({ id })
      .then((resultado) => resultado as IRespostaFuncao<IMeta>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao remover Meta',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
export const Metas = {
    obtem,
    cria,
    atualiza,
    deleta
};
  