import {
  META_LIST_METAS_INIT,
  META_LIST_METAS_SUCCESS,
  CREATE_METAS_INIT,
  CREATE_METAS_SUCCESS,
  UPDATE_META_INIT,
  UPDATE_META_SUCCESS,
  META_DELETE_META_INIT,
  META_DELETE_META_SUCCESS,  
  META_OBTEM_META_SUCCESS,
  META_OBTEM_META_INIT,
  METAS_ERROR_MSG,
  CLEAR_METAS,
} from '../actionTypes';


export const metaListMetasInit = (data) => ({
  type: META_LIST_METAS_INIT,
  payload: data
});

export const metaListMetasSuccess = (data) => ({
  type: META_LIST_METAS_SUCCESS,
  payload: data
});

export const obtemMetaInit = (data) => ({
  type: META_OBTEM_META_INIT,
  payload: data
});

export const obtemMetaSuccess = (data) => ({
  type: META_OBTEM_META_SUCCESS,
  payload: data
});

export const createMetasInit = (data) => ({
  type: CREATE_METAS_INIT,
  payload: data
});

export const createMetasSucess = (data) => {
  return {
    type: CREATE_METAS_SUCCESS,
    payload: data
  };
};

export const updateMetaInit = (data) => ({
  type: UPDATE_META_INIT,
  payload: data
});

export const updateMetaSuccess = (data) => {
  return {
    type: UPDATE_META_SUCCESS,
    payload: data
  };
};

export const metaDeleteMetaInit = (data) => ({
  type: META_DELETE_META_INIT,
  payload: data
});

export const metaDeleteMetaSuccess = (data) => ({
  type: META_DELETE_META_SUCCESS,
  payload: data
});

export const clearMetas = () => ({
  type: CLEAR_METAS,
  payload: {}
});

export const metaErrorMsg = (data) => ({
  type: METAS_ERROR_MSG,
  payload: data
});

