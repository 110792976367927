// componentsByRole(<array_de_objetos>)

// Recebe um objeto ou array de objetos
// com uma propriedade allowedRoles contendo
// um array dos papéis autorizados a acessar
// o componente construído a partir daquele objeto

// Retorna o objeto ou objetos do array cujos
// contém o papel do usuário logado no sistema possui

/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
// import { useSelector, shallowEqual } from 'react-redux';
import { PAPELDEFAULT } from 'src/utils/rbac/papeis';

const HasAccessItemsMenu = ({ items, myPrivilege = PAPELDEFAULT }) => {

    // const { myPrivilege } = useSelector(
    //     (state) => ({
    //         myPrivilege: String(state.account.user?.privilegeHash || PAPELDEFAULT)
    //     }),
    //     shallowEqual
    // );
    const checkPermissions = (allowedRoles) => {
        if (!allowedRoles) {
            return true;
        }
        return myPrivilege && allowedRoles.some((permission) => myPrivilege.includes(permission));
    };

    const filteredItems = items.filter((item) => checkPermissions(item.allowedRoles));

    return filteredItems; // Retorne os itens filtrados
};


export default HasAccessItemsMenu;