import { User as RealmUser } from 'realm-web';
import { IMetricaVisualizacaoDashboard } from '../modelos/IMetricaVisualizacaoDashboard';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ ENVIAR LOG ------------------------ //
const enviaLog = async (
    adminMongoDB: RealmUser,
    log: IMetricaVisualizacaoDashboard
  ): Promise<IRespostaFuncao<IMetricaVisualizacaoDashboard>> => {
    return adminMongoDB.functions['logs/criaLog'](log)
      .then((resultado) => resultado as IRespostaFuncao<IMetricaVisualizacaoDashboard>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao enviar Logs',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

export const Logs = {
  enviaLog,
}
  