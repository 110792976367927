import app from './app';
import { mongoDBName } from '../../config';

const mongodb = mongoDBName as string;

let dashTypes: any;
let empresas: any;

const initializeMongoCollections = async () => {
  if (dashTypes && empresas) {
    // Se as coleções já foram inicializadas, apenas retorna
    return { dashTypes, empresas };
  }

  try {
    await app.currentUser?.refreshCustomData();
    const mongo = app.currentUser?.mongoClient('mongodb-atlas');
    if (mongo) {
      dashTypes = mongo.db(mongodb).collection('dashTypes');
      empresas = mongo.db(mongodb).collection('empresas');
    } else {
      throw new Error('Falha ao inicializar client MongoDB');
    }
  } catch (error) {
    throw new Error('Erro ao inicializar collections MongoDB');
  }

  return { dashTypes, empresas };
};

export default initializeMongoCollections;
