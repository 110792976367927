// função que faz o parse do IUsuarioFormulário
// para IUsuárioMongoDB

import {
  IUsuarioFormulario,
} from 'src/integracoes/modelos/usuarios';
import { IUsuarioMongoDB } from '../../modelos/usuarios';

// recebe um IUsuarioFormulário
// retorna um IUsuárioMongoDB
export function montaUsuarioMongoDB(
  usuarioFormulario: IUsuarioFormulario
): IUsuarioMongoDB {
  const usuarioMongoDB: IUsuarioMongoDB = {
    firebaseId: usuarioFormulario.id,
    name: usuarioFormulario.name,
    email: usuarioFormulario.email,
    empId: usuarioFormulario.empId,
    loja: usuarioFormulario.loja,
    job: usuarioFormulario.job,
    isAdmin: usuarioFormulario.isAdmin,
    isActive: usuarioFormulario.isActive,
    privilegeHash: parseInt(usuarioFormulario.privilegeHash, 10)
  };
  return usuarioMongoDB;
}
