import {
  AUTH_STATE_CHANGED,
  USER_DATA_INIT,
  AUTH_INIT,
  CLEAR_USER_AUTH,
  GET_EMPRESA_INIT,
  GET_EMPRESA_SUCCESS,
  SET_EMPRESA,
  AUTH_ERROR_MSG,
} from '../actionTypes';

const INIT_STATE = {
  isAuthenticated: false,
  isInitialised: false,
  loading: false,
  user: null,
  userPrivileges: {},
  myEmp: {},
  empId: '',
  msg: null,
  isReceived: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_STATE_CHANGED: {
      const { isAuthenticated, user, loading, userPrivileges } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        loading,
        user: user,
        userPrivileges,
        myEmp: user?.myEmp
      };
    }
    case AUTH_INIT:
      return { ...state, loading: true, error: '' };
    case USER_DATA_INIT:
      return { ...state, loading: true, dataRequested: true, error: '' };
    case GET_EMPRESA_INIT: {
      const { empId } = action.payload;
      return {
        ...state,
        loading: true,
        isReceived: false,
        empId
      };
    }
    case GET_EMPRESA_SUCCESS: {
      const { myEmp } = action.payload;
      return {
        ...state,
        loading: false,
        isReceived: true,
        user: {
          ...state.user,
          myEmp: myEmp
        },
        myEmp: myEmp
      };
    }
    case AUTH_ERROR_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        msg: msg
      };
    }
    case SET_EMPRESA: {
      const { empresa } = action.payload;
      return {
        ...state,
        myEmp: empresa
      };
    }
    case CLEAR_USER_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isInitialised: false,
        user: null,
        permissionsUserType: [],
        myEmp: {},
        empId: '',
        isReceived: false
      };
    default:
      return { ...state };
  }
};
