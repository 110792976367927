import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { IUsuarioMongoDB } from '../../../modelos/usuarios';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IUsuarioFormulario } from 'src/integracoes/modelos/usuarios';
import { montaUsuarioMongoDB } from '../utils';
import { IPapel } from '../../../modelos/usuarios';

// ------------------------ OBTEM DADOS USUÁRIO ATUAL ------------------------ //
const obtem = async (
    adminMongoDB: RealmUser
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    return adminMongoDB.functions['empresas/usuarios/obtemUsuario']()
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao obter dados do Usuário',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ CRIAR USUÁRIO ------------------------ //
const cria = async (
    adminMongoDB: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuarioMongoDB: IUsuarioMongoDB = montaUsuarioMongoDB(usuarioFormulario);
    return adminMongoDB.functions['empresas/usuarios/criaUsuario'](usuarioMongoDB)
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar usuário na base de dados',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ ATUALIZAR USUÁRIO ------------------------ //
const atualiza = async (
    adminMongoDB: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuarioMongoDB: IUsuarioMongoDB = montaUsuarioMongoDB(usuarioFormulario);
    const dadosUsuario: IUsuarioMongoDB = {
      firebaseId: usuarioMongoDB.firebaseId,
      name: usuarioMongoDB.name,
      isAdmin: usuarioMongoDB.isAdmin,
      privilegeHash: usuarioMongoDB.privilegeHash,
      loja: usuarioMongoDB.loja,
      job: usuarioMongoDB.job,
    };
    return adminMongoDB.functions['empresas/usuarios/atualizaUsuario'](dadosUsuario)
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao atualizar usuário na base de dados',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ DELETA USUÁRIO ------------------------ //
const deleta = async (
    adminMongoDB: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuarioMongoDB: IUsuarioMongoDB = montaUsuarioMongoDB(usuarioFormulario);
    return adminMongoDB.functions['empresas/usuarios/removeUsuario']({ firebaseId: usuarioMongoDB.firebaseId })
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao remover usuário na base de dados',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ ATRIBUIR EMPRESAS AO USUÁRIO ------------------------ //
const atribuiEmpresas = async (
    adminMongoDB: RealmUser,
    usuarioFormulario: IUsuarioFormulario
  ): Promise<IRespostaFuncao<IUsuarioMongoDB>> => {
    const usuario: IUsuarioMongoDB = {
      firebaseId: usuarioFormulario.id,
      emps: usuarioFormulario.emps
    };
    return adminMongoDB.functions['usuarios/atribuiEmpresas'](usuario)
      .then((resultado) => resultado as IRespostaFuncao<IUsuarioMongoDB>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao atribuir Empresas ao Usuário',
          causa: e
        };
        throw new FarmaError(erro);
    });
};

// ------------------------ OBTEM PAPEL USUARIO ------------------------ //
const obtemPapelUsuario = async (
    adminMongoDB: RealmUser,
  ): Promise<IRespostaFuncao<IPapel>> => {
    return adminMongoDB.functions['usuarios/papeis/obtemPapel']()
      .then((resultado) => resultado as IRespostaFuncao<IPapel>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao obter Papel do Usuário Atual',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  

export const Usuarios = {
    obtem,
    cria,
    atualiza,
    deleta,
    obtemPapelUsuario,
    atribuiEmpresas,
};
  