import {
  CREATE_DASHBOARD_INIT,
  CREATE_DASHBOARD_SUCCESS,
  ADMIN_ERROR_MSG,
  CREATE_TIPOS_DASH_INIT,
  CREATE_TIPOS_DASH_SUCCESS,
  ADMIN_CLEAR_ALL,
  GET_DASHBOARDS_INIT,
  GET_DASHBOARDS_SUCCESS,
  UPDATE_DASHBOARD_INIT,
  DELETE_DASHBOARD_INIT,
  UPDATE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  error: '',
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  isError: false,
  isReceived: false,
  empSelected: {},
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_TIPOS_DASH_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case CREATE_TIPOS_DASH_SUCCESS: {
      const { msg, isCreated, isDeleted, isUpdated } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isCreated,
        isDeleted,
        isUpdated
      };
    }
    case CREATE_DASHBOARD_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false
    };
    case UPDATE_DASHBOARD_INIT:
      return {
        ...state,
        loading: true,
        isUpdated: false
    };
    case DELETE_DASHBOARD_INIT:
      return {
        ...state,
        loading: true,
        isDeleted: false
    };
    case CREATE_DASHBOARD_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isCreated: true
      };
    }
    case UPDATE_DASHBOARD_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isUpdated: true
      };
    }
    case DELETE_DASHBOARD_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isDeleted: true
      };
    }
    case ADMIN_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case GET_DASHBOARDS_INIT: {
      return {
        ...state,
        loading: true,
        isUpdated: false,
        isReceived: false
      };
    }
    case GET_DASHBOARDS_SUCCESS: {
      const { emp } = action.payload;
      return {
        ...state,
        loading: false,
        isUpdated: false,
        isReceived: true,
        empSelected: emp
      };
    }
    case ADMIN_CLEAR_ALL:
      return {
        ...state,
        ...INIT_STATE
      };
    default:
      return { ...state };
  }
};
