import {
  LIST_COLABORADOR_SUCCESS,
  LIST_COLABORADOR_INIT,
  CREATE_COLABORADOR_SUCCESS,
  CREATE_COLABORADOR_INIT,
  EDIT_COLABORADOR_SUCCESS,
  EDIT_COLABORADOR_INIT,
  COLABORADORES_ERRO_MSG,
  CLEAR_COLABORADORES
} from '../actionTypes';


export const listColaboradoresInit = (data) => ({
  type: LIST_COLABORADOR_INIT,
  payload: data
});

export const listColaboradoresSuccess = (data) => ({
  type: LIST_COLABORADOR_SUCCESS,
  payload: data
});

export const createColaboradorInit = (data) => ({
  type: CREATE_COLABORADOR_INIT,
  payload: data
});

export const createColaboradorSuccess = (data) => ({
  type: CREATE_COLABORADOR_SUCCESS,
  payload: data
});

export const editColaboradorInit = (data) => ({
  type: EDIT_COLABORADOR_INIT,
  payload: data
});

export const editColaboradorSuccess = (data) => ({
  type: EDIT_COLABORADOR_SUCCESS,
  payload: data
});

export const colaboradorErrorMsg = (data) => ({
  type: COLABORADORES_ERRO_MSG,
  payload: data
});

export const clearColaborador = () => ({
  type: CLEAR_COLABORADORES,
  payload: {}
});

