import { LOG_ERROR_MSG, SEND_LOG } from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isError: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_LOG:
      return { ...state, loading: true, isError: false };

    case LOG_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, isError: true, msg };
    }

    default:
      return { ...state };
  }
};
