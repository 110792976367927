export class FarmaError extends Error {
  nome: string;
  mensagem: string;
  causa: any;

  constructor({
    nome,
    mensagem,
    causa
  }: {
    nome: string;
    mensagem: string;
    causa?: any;
  }) {
    super();
    this.nome = nome;
    this.mensagem = mensagem;
    this.causa = causa;
  }
}

export class FarmaFuncaoError extends FarmaError {}
