import {
  CLEAR_FLAGS_CURVA,
  SET_CURVA_ABC_INIT,
  SET_CURVA_ABC_SUCCESS,
  ERROR_CURVA_MSG
} from '../actionTypes';

export const clearFlagsCurva = () => ({
  type: CLEAR_FLAGS_CURVA,
  payload: {}
});

export const setCurvaAbcInit = ({ mUser, empId, categorias, parametroD }) => ({
  type: SET_CURVA_ABC_INIT,
  payload: { mUser, empId, categorias, parametroD }
});

export const setCurvaAbcSuccess = ({ msg }) => {
  return {
    type: SET_CURVA_ABC_SUCCESS,
    payload: { msg }
  };
};

export const errorCurvaMsg = (data) => ({
  type: ERROR_CURVA_MSG,
  payload: data
});
