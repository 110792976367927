import {
  LIST_TIPOS_DASH_INIT,
  LIST_TIPOS_DASH_SUCCESS,
  DASH_ERROR_MSG,
  LIST_MY_DASHS_INIT,
  LIST_MY_DASHS_SUCCESS,
  CLEAR_DASH_STATUS,
  SET_DASHBOARDS_USER_INIT,
  SET_DASHBOARDS_USER_SUCCESS,
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isError: false,
  tiposDash: [],
  dashs: [],
  idTipos: '',
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DASH_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, isError: true, msg };
    }
    case LIST_TIPOS_DASH_INIT:
      return { ...state, loading: true, isUpdated: false };

    case LIST_TIPOS_DASH_SUCCESS: {
      const { tiposDash, idTipos } = action.payload;
      return { ...state, tiposDash, idTipos, loading: false };
    }
    case LIST_MY_DASHS_INIT:
      return { ...state, loading: true, isReceived: false };

    case LIST_MY_DASHS_SUCCESS: {
      const { dashs } = action.payload;
      return {
        ...state,
        dashs,
        isReceived: true,
        loading: false
      };
    }
    case SET_DASHBOARDS_USER_INIT:
      return { ...state, loading: true, isUpdated: false };

    case SET_DASHBOARDS_USER_SUCCESS: {
      const { isError, msg } = action.payload;
      return {
        ...state,
        isError,
        msg,
        isUpdated: true,
        loading: false
      };
    }
    case CLEAR_DASH_STATUS: {
      return {
        ...state,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isError: false,
        loading: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
