import {
  ADMIN_ERROR_MSG,
  CREATE_TIPOS_DASH_SUCCESS,
  CREATE_TIPOS_DASH_INIT,
  CREATE_DASHBOARD_INIT,
  CREATE_DASHBOARD_SUCCESS,
  ADMIN_CLEAR_ALL,
  GET_DASHBOARDS_INIT,
  GET_DASHBOARDS_SUCCESS,
  DELETE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_INIT,
  DELETE_DASHBOARD_INIT
} from '../actionTypes';

export const adminErrorMsg = (data) => ({
  type: ADMIN_ERROR_MSG,
  payload: data
});

export const createTiposDashSuccess = ({ isUpdated, isCreated, isDeleted, msg }) => ({
  type: CREATE_TIPOS_DASH_SUCCESS,
  payload: { isUpdated, isCreated, isDeleted, msg }
});

export const createTiposDashInit = ({ id, tipo, operation }) => ({
  type: CREATE_TIPOS_DASH_INIT,
  payload: { id, tipo, operation }
});

export const createDashboardInit = ({ empId, dash }) => ({
  type: CREATE_DASHBOARD_INIT,
  payload: { empId, dash }
});

export const updateDashboardInit = ({ empId, dash }) => ({
  type: UPDATE_DASHBOARD_INIT,
  payload: { empId, dash }
});

export const deleteDashboardInit = ({ empId, dash }) => ({
  type: DELETE_DASHBOARD_INIT,
  payload: { empId, dash }
});

export const createDashboardSuccess = ({ isCreated, msg }) => ({
  type: CREATE_DASHBOARD_SUCCESS,
  payload: { isCreated, msg }
});

export const updateDashboardSuccess = ({ isUpdated, msg }) => ({
  type: UPDATE_DASHBOARD_SUCCESS,
  payload: { isUpdated, msg }
});

export const deleteDashboardSuccess = ({ isDeleted, msg }) => ({
  type: DELETE_DASHBOARD_SUCCESS,
  payload: { isDeleted, msg }
});

export const adminClearAll = (data) => ({
  type: ADMIN_CLEAR_ALL,
  payload: data
});

export const getDashboardsInit = (data) => ({
  type: GET_DASHBOARDS_INIT,
  payload: data
});

export const getDashboardsSuccess = (data) => ({
  type: GET_DASHBOARDS_SUCCESS,
  payload: data
});
