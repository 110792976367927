import {
  CLEAR_FLAGS_ESTOQUE,
  SET_ESTOQUE_DADOS_INIT,
  SET_ESTOQUE_DADOS_SUCCESS,
  ERROR_ESTOQUE_MSG
} from '../actionTypes';

export const clearFlagsEstoque = () => ({
  type: CLEAR_FLAGS_ESTOQUE,
  payload: {}
});

export const setExcessoEstoqueInit = ({ mUser, empId, grupos }) => ({
  type: SET_ESTOQUE_DADOS_INIT,
  payload: { mUser, empId, grupos }
});

export const setExcessoEstoqueSuccess = ({ msg }) => {
  return {
    type: SET_ESTOQUE_DADOS_SUCCESS,
    payload: { msg }
  };
};

export const errorEstoqueMsg = (data) => ({
  type: ERROR_ESTOQUE_MSG,
  payload: data
});
