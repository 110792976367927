import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { User as RealmUser } from 'realm-web';
import firebase from '../../lib/firebase';
import { FarmaError, FarmaFuncaoError } from 'src/integracoes/modelos/erros';
import { authStateChanged, clearUserAuth, authErrorMsg, getEmpresaSuccess } from './actions';
import {
  listTiposDashInit,
  listMyDashsInit
} from '../dashs/actions';
import {
  USER_DATA_INIT,
  AUTH_STATE_CHANGED,
  GET_EMPRESA_INIT
} from '../actionTypes';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { IUsuarioMongoDB } from 'src/integracoes/modelos/usuarios';
import { adminClearAll } from '../actions';
import { IEmpresa } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';
import { listCategoriaPainelInit } from '../categorias/actions';
import { IPapel } from 'src/integracoes/modelos/usuarios';
import { Usuarios } from 'src/integracoes/servicos/mongo-atlas/funcoes/usuarios';


// -------------------- Buscar dados usuário -----------------------------------------
const obtemDadosUsuarioAtual = async (dados: {
  empId: string;
  privilegeHash: string
  adminMongoDB: RealmUser;
}) => {
  try {

    const { adminMongoDB, empId } = dados;

    const [respostaUsuarioAtual, respostaEmpresaAtual, respostaPrivilegeAtual] = await Promise.all([
      Usuarios.obtem(adminMongoDB),
      Empresas.obtem(adminMongoDB, empId),
      Usuarios.obtemPapelUsuario(adminMongoDB)
    ]);

    const conditions = [
      { status: respostaUsuarioAtual.status, errorData: respostaUsuarioAtual.dados },
      { status: respostaEmpresaAtual.status, errorData: respostaEmpresaAtual.dados },
      { status: respostaPrivilegeAtual.status, errorData: respostaPrivilegeAtual.dados }
    ];

    for (const condition of conditions) {
      if (!condition.status) {
        throw condition.errorData as FarmaFuncaoError;
      }
    }

    const usuarioAtual = respostaUsuarioAtual.dados as IUsuarioMongoDB;
    const empresaAtual = respostaEmpresaAtual.dados as IEmpresa;
    const usuarioPapelAtual = respostaPrivilegeAtual.dados as IPapel;

    const userData = {
      id: usuarioAtual.firebaseId,
      empId: usuarioAtual.empId,
      avatar: '',
      email: usuarioAtual.email || '',
      name: usuarioAtual.name || '',
      myEmp: {},
      isAdmin: usuarioAtual.isAdmin,
      privilege: '',
      privilegeHash: usuarioAtual.privilegeHash,
      myLoja: '',
      erpId: '',
      myJob: '',
      // paineis: usuarioAtual.paineis || []
    };

    userData.myEmp = {
      id: empresaAtual.id,
      status: empresaAtual.status,
      name: empresaAtual.name,
      lojas: empresaAtual.lojas,
      grupos: empresaAtual.grupos,
      groupControl: empresaAtual.groupControl,
      dashboards: empresaAtual.dashboards,
      // pbiRelatorio: empresaAtual.pbiRelatorio,
      indelible: false,
      config: empresaAtual.config
    };
    return {
      userData: userData,
      usuarioPrivilegios: usuarioPapelAtual
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }

};

export function* parseObtemDadosUsuarioAtual({ payload }: { payload: any; type: string }) {
  try {
    const empId = payload.empId;
    const privilegeHash = payload.privilegeHash;
    const adminMongoDB: RealmUser = payload.mongoUser.user;
    const dados = { empId, privilegeHash, adminMongoDB };
    const {
      userData,
      usuarioPrivilegios
    } = yield call(obtemDadosUsuarioAtual, dados);
    userData.mongoUser = payload.mongoUser;
    yield put(
      authStateChanged({
        isAuthenticated: true,
        loading: false,
        user: userData,
        userPrivileges: usuarioPrivilegios,
        myEmp: userData.myEmp,
        empId: userData.myEmp.id
      })
    );
  } catch (error: any) {
    yield put(adminClearAll());
    yield put(clearUserAuth());
    return firebase.auth().signOut();
  }
}

export function* handleGetUserData() {
  yield takeEvery(USER_DATA_INIT, parseObtemDadosUsuarioAtual);
}

// -------------------- Mudança de estado após Login -----------------------------------------
export function* parseAuthStateChange({ payload }: { payload: any; type: string }) {
  const { isAuthenticated, user } = payload;
  if (isAuthenticated) {
    try {
      yield put(listTiposDashInit());
      yield put(listCategoriaPainelInit({ mUser: user.mongoUser }));
      yield put(
        listMyDashsInit({
          mUser: user.mongoUser,
          empId: user.myEmp.id,
          userId: user.id
        })
      );
    } catch (error) {
      const erro = {
        nome: 'AUTH_STATE_CHANGE',
        mensagem: 'Erro ao mudar estado de login',
        causa: error
      };
      throw new FarmaError(erro);
    }
  }
}

export function* handleAuthStateChange() {
  yield takeEvery(AUTH_STATE_CHANGED, parseAuthStateChange);
}

// -------------------- Buscar dados da Empresa Atual do sistema  -----------------------------------------
const obterEmpresaMongoDB = async ({
  mUser,
  empId
}: {
  mUser: any;
  empId: string;
}): Promise<any> => {
  try {
    const { status, dados, mensagem } = await Empresas.obtem(mUser.user, empId);
    if (status) {
      return { isReceived: true, data: dados, msg: mensagem };
    } else {
      return { isReceived: false, data: {}, msg: mensagem };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseObterDadosEmpresa({ payload }: { payload: any; type: any }) {
  const { mUser, empId } = payload;
  try {
    const dados: {
      isReceived: boolean;
      data: IEmpresa;
      msg: string;
    } = yield call(obterEmpresaMongoDB, {
      mUser,
      empId
    });
    if (dados.isReceived) {
      yield put(
        getEmpresaSuccess({
          myEmp: dados.data
        })
      );
    }
    else {
      yield put(authErrorMsg({ msg: dados.msg }))
    }
  } catch (error: any) {
    yield put(authErrorMsg({ msg: error.mensagem }))
  }
}
export function* handleObterDadosEmpresa() {
  yield takeEvery(GET_EMPRESA_INIT, parseObterDadosEmpresa);
}


export default function* rootSaga() {
  yield all([
    fork(handleGetUserData),
    fork(handleAuthStateChange),
    fork(handleObterDadosEmpresa),
  ]);
}
