import packageJson from '../package.json';
export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID
};

export const urlClient = process.env.REACT_APP_URL_CLIENT; // 'https://pgfarma.digital/';

export const mongoAppId = process.env.REACT_APP_MONGO_APP_ID;

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENTE_ID;

export const privileges = {
  likeABoss: process.env.REACT_APP_PRIVILEGE_ADMIN,
  gestorLocal: process.env.REACT_APP_PRIVILEGE_GESTOR_LOCAL,
  padrao: process.env.REACT_APP_PRIVILEGE_PADRAO,
  gestorGeral: process.env.REACT_APP_PRIVILEGE_GESTOR_GERAL
};

export const mongoDBName = process.env.REACT_APP_MONGO_DB_NAME;

export const primaryColor = process.env.REACT_APP_BRAND_PRIMARY_COLOR;

export const versaoFrontend = packageJson.version;
