import {
  CLEAR_FLAGS_ESTOQUE,
  SET_ESTOQUE_DADOS_INIT,
  SET_ESTOQUE_DADOS_SUCCESS,
  ERROR_ESTOQUE_MSG
} from '../actionTypes';

const INIT_STATE = {
  isUpdated: false,
  isError: false,
  isReceived: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ERROR_ESTOQUE_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        isUpdated: false,
        isReceived: false,
        msg,
        isError: true
      };
    }
    case SET_ESTOQUE_DADOS_INIT:
      return { ...state, isUpdated: false };
    case SET_ESTOQUE_DADOS_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isUpdated: true, msg };
    }
    case CLEAR_FLAGS_ESTOQUE: {
      return {
        ...state,
        isUpdated: false,
        isError: false,
        isReceived: false
      };
    }
    default:
      return { ...state };
  }
};
