import {
  SET_CONTROL_GROUP_INIT,
  SET_CONTROL_GROUP_SUCCESS,
  GROUP_ERROR_MSG,
  CLEAR_GROUP
} from '../actionTypes';

export const groupControlSetInit = (data) => ({
  type: SET_CONTROL_GROUP_INIT,
  payload: data
});

export const groupControlSetSuccess = (data) => ({
  type: SET_CONTROL_GROUP_SUCCESS,
  payload: data
});

export const groupErrorMsg = (data) => ({
  type: GROUP_ERROR_MSG,
  payload: data
});

export const clearGroup = (data) => ({
  type: CLEAR_GROUP,
  payload: data
});
