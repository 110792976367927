export const PAPELDEFAULT = '00000';
export const PAPEL1 = '10000';
export const PAPEL2 = '21430';
export const PAPEL3 = '43334';
export const PAPEL4 = '44444';

/* display name usuarios */
export const GESTAO = 'Gestão';
export const ADMINISTRACAO = 'Administração';
export const VISUALIZACAO = 'Visualização';
export const SUPERADMIN = 'Super Admin';
