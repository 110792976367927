// função que faz o parse do IUsuarioFormulário
// para IUsuárioMongoDB

import {
  IPrivilege,
  IUsuarioFirebase,
  IUsuarioFormulario
} from 'src/integracoes/modelos/usuarios';

function montaPrivilege(privilegeHash: string): IPrivilege {
  const privilege = {
    dash: parseInt(privilegeHash.charAt(0), 10),
    mod: parseInt(privilegeHash.charAt(1), 10),
    user: parseInt(privilegeHash.charAt(2), 10),
    loja: parseInt(privilegeHash.charAt(3), 10),
    emp: parseInt(privilegeHash.charAt(4), 10)
  };
  return privilege;
}

// recebe um IUsuarioFormulário
// retorna um IUsuárioFirebase
export function montaUsuarioFirebase(
  usuarioFormulario: IUsuarioFormulario
): IUsuarioFirebase {
  const usuarioFirebase: IUsuarioFirebase = {
    uid: usuarioFormulario.id,
    email: usuarioFormulario.email,
    desativado: !usuarioFormulario.isActive, // validar essa parte
    displayName: usuarioFormulario.name,
    empresa: usuarioFormulario.empId,
    isAdmin: usuarioFormulario.isAdmin,
    privilegeHash: parseInt(usuarioFormulario.privilegeHash, 10),
    privilege: montaPrivilege(usuarioFormulario.privilegeHash)
  };
  return usuarioFirebase;
}
