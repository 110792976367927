import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import { SET_CONTROL_GROUP_INIT } from '../actionTypes';
import { groupControlSetSuccess, groupErrorMsg } from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';

// ------------ set grupos de controle --------------------

const setGroups = async ({ empresa, groupControl, mUser }: { empresa: any , groupControl: any, mUser: any }) => {
  const empId = empresa;
  try {
    const {status} = await Empresas.atualizaGruposControle(mUser.user, empId, groupControl);
    return {
      isError: !status,
      msg: status ? 'Grupos atualizados com Sucesso' : 'Erro ao atualizar grupos'
    };
  } catch (error: any) {
    return {
      isError: true,
      msg: `Erro ao atualizar grupo: ${error.mensagem}`
    };
  }
};

export function* parseSetGroups({ payload }: {payload: any, type: any}) {
  const { empresa, groupControl, mUser } = payload;
  const { isError, msg } = yield call(setGroups, {
    empresa,
    groupControl,
    mUser
  });
  if (!isError) {
    yield put(groupControlSetSuccess({ msg }));
  } else {
    yield put(groupErrorMsg({ msg: msg }));
  }
}
export function* watchSetGroups() {
  yield takeEvery(SET_CONTROL_GROUP_INIT, parseSetGroups);
}

export default function* rootSaga() {
  yield all([fork(watchSetGroups)]);
}
