import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { FarmaError } from "src/integracoes/modelos/erros";
import { app } from "./app";

const storage = getStorage(app);

const obtemUrlArquivoNoStorage = async (
    imagem: string
): Promise<string> => {
    try {
        const storageRef = ref(storage, imagem);
        return getDownloadURL(storageRef);
    } catch (e) {
        const erro = {
            nome: 'FIREBASE-ERRO-DESCONHECIDO',
            mensagem: 'Erro ao obter a URL de um arquivo serviço de armazenamento',
            causa: e
        };
        throw new FarmaError(erro);
    }
};

export const firebaseStorage = {
    obtemUrlArquivoNoStorage
}