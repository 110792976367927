import {
  SET_CONTROL_GROUP_INIT,
  SET_CONTROL_GROUP_SUCCESS,
  GROUP_ERROR_MSG,
  CLEAR_GROUP
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isUpdated: false,
  isError: false,
  current: null,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONTROL_GROUP_INIT: {
      return {
        ...state,
        isUpdated: false
      };
    }
    case SET_CONTROL_GROUP_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        isUpdated: true,
        msg
      };
    }

    case GROUP_ERROR_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isError: true
      };
    }

    case CLEAR_GROUP:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        isCreated: false,
        isError: false,
        current: null,
        msg: ''
      };

    default:
      return { ...state };
  }
};
