import {
  LIST_TIPOS_DASH_INIT,
  LIST_TIPOS_DASH_SUCCESS,
  LIST_MY_DASHS_INIT,
  LIST_MY_DASHS_SUCCESS,
  CLEAR_DASH_STATUS,
  SET_DASHBOARDS_USER_INIT,
  SET_DASHBOARDS_USER_SUCCESS,
  DASH_ERROR_MSG,
} from '../actionTypes';

export const listTiposDashInit = () => ({
  type: LIST_TIPOS_DASH_INIT,
  payload: {}
});

export const listTiposDashSuccess = ({ idTipos, tiposDash }) => {
  return {
    type: LIST_TIPOS_DASH_SUCCESS,
    payload: { idTipos, tiposDash }
  };
};

export const listMyDashsInit = ({ mUser, empId, userId }) => ({
  type: LIST_MY_DASHS_INIT,
  payload: { mUser, empId, userId }
});

export const listMyDashsSuccess = ({ dashs }) => {
  return {
    type: LIST_MY_DASHS_SUCCESS,
    payload: { dashs }
  };
};

export const setDashsToUserInit = ({ mUser, empId, userId, dashboards, before, atual }) => {
  return {
    type: SET_DASHBOARDS_USER_INIT,
    payload: { mUser, empId, userId, dashboards, before, atual }
  };
};

export const setDashsToUserSuccess = ({ isError, msg }) => {
  return {
    type: SET_DASHBOARDS_USER_SUCCESS,
    payload: { isError, msg }
  };
};

export const dashErroMsg = (data) => ({
  type: DASH_ERROR_MSG,
  payload: data
});

export const painelClearStatus = (data) => ({
  type: CLEAR_DASH_STATUS,
  payload: data
});

