import {
  CLEAR_FLAGS_CURVA,
  SET_CURVA_ABC_INIT,
  SET_CURVA_ABC_SUCCESS,
  ERROR_CURVA_MSG
} from '../actionTypes';

const INIT_STATE = {
  isUpdated: false,
  isError: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ERROR_CURVA_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        isUpdated: false,
        msg,
        isError: true
      };
    }
    case SET_CURVA_ABC_INIT:
      return { ...state, isUpdated: false };
    case SET_CURVA_ABC_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isUpdated: true, msg };
    }
    case CLEAR_FLAGS_CURVA: {
      return {
        ...state,
        isUpdated: false,
        isError: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
