import { Router } from 'react-router-dom';
import type { FC } from 'react';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import routes, { renderRoutes } from './routes';
import { obtemUrlDeArquivoNaSessionStorage } from './utils/functions';

const jss = create({ plugins: [...jssPreset().plugins] });
const history = createBrowserHistory();
const App: FC = () => {
  const { settings } = useSettings();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const favicon = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement | null;
  
  if (favicon) {
    obtemUrlDeArquivoNaSessionStorage('favicon.ico').then((url: string) => {
      favicon.href = url;
    }).catch((e) => {
      favicon.href = '/favicon-error.ico';
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
