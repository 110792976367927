import {
  CREATE_EMPRESA_INIT,
  CREATE_EMPRESA_SUCCESS,
  DELETE_EMPRESA_INIT,
  DELETE_EMPRESA_SUCCESS,
  LIST_EMPRESA_INIT,
  LIST_EMPRESA_SUCCESS,
  MODIFY_EMPRESA_SUCCESS,
  MODIFY_EMPRESA_INIT,
  EMPRESA_CLEAN_UP,
  EMPRESA_ERROR_MSG,
  EMPRESA_CLEAN_LIST_EMPS
} from '../actionTypes';

export const createEmpresaInit = (data) => ({
  type: CREATE_EMPRESA_INIT,
  payload: data
});

export const createEmpresaSuccess = (data) => ({
  type: CREATE_EMPRESA_SUCCESS,
  payload: data
});

export const modifyEmpresaSuccess = (data) => ({
  type: MODIFY_EMPRESA_SUCCESS,
  payload: data
});

export const modifyEmpresaInit = (data) => ({
  type: MODIFY_EMPRESA_INIT,
  payload: data
});

export const deleteEmpresaInit = (data) => ({
  type: DELETE_EMPRESA_INIT,
  payload: data
});

export const deleteEmpresaSuccess = (data) => ({
  type: DELETE_EMPRESA_SUCCESS,
  payload: data
});

export const listEmpresaInit = (data) => ({
  type: LIST_EMPRESA_INIT,
  payload: data
});

export const listEmpresaSuccess = (empresas, statusEmpresas) => {
  return {
    type: LIST_EMPRESA_SUCCESS,
    payload: { allEmps: empresas, allStatusEmps: statusEmpresas }
  };
};

export const empresaCleanUp = (data) => ({
  type: EMPRESA_CLEAN_UP,
  payload: data
});

export const empresaErrorMsg = (data) => ({
  type: EMPRESA_ERROR_MSG,
  payload: data
});

export const empresaCleanListEmps = (data) => ({
  type: EMPRESA_CLEAN_LIST_EMPS,
  payload: data
});

