import { useEffect, useState } from 'react';
import { LOGO_ERROR } from 'src/constants';
import { obtemUrlDeArquivoNaSessionStorage } from 'src/utils/functions';

const Logo = (props: any) => {
  const [logoUrl, setLogoUrl] = useState<string>('');

  useEffect(() => {
    // Variável utilizada para parar as rechamadas
    // se o component já tiver sido desmontado
    let cancelado = false;

    // função de parse utilizada para chamar
    // funções assíncronas dentro do useEffect
    async function configuraLogoUrl() {
      const urlArquivo = await obtemUrlDeArquivoNaSessionStorage('logo.svg');
      if (!cancelado) {
        setLogoUrl(urlArquivo);
      }
    }  
 
    try {
      configuraLogoUrl();
    } catch (e) {
      setLogoUrl(LOGO_ERROR);
    }

    // função chamada no momento em que o componente
    // é remontado ou desmontado
    return () => { cancelado = true; };
  });

  return (
    <img
      alt="Logo"
      src={logoUrl}
      width="80px"
      {...props}
    />
  )
};

export default Logo;
