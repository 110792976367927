/* eslint-disable prettier/prettier */
import * as Realm from 'realm-web';

export const mongoSetDashsToUser = async (
  user: Realm.User,
  empId: string,
  userId: string,
  dashboards: any[],
  before: any[],
  atual: any[]
): Promise<any> => {
  const result: any = await user.callFunction(
    'setDashsToUser',
    empId,
    userId,
    dashboards,
    before,
    atual
  );
  return result;
};

export const mongoGetMyDashs = async (
  user: Realm.User,
  empId: string,
  userId: string
): Promise<boolean> => {
  const result: any = await user.callFunction('getMyDashs', empId, userId);
  return result;
};
