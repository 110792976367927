import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_METAS_INIT,
  UPDATE_META_INIT,
  META_LIST_METAS_INIT,
  META_DELETE_META_INIT,
  META_OBTEM_META_INIT
} from '../actionTypes';
import {
  createMetasSucess,
  updateMetaSuccess,
  metaErrorMsg,
  metaListMetasSuccess,
  metaDeleteMetaSuccess,
  obtemMetaSuccess
} from './actions';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IMeta } from 'src/integracoes/servicos/mongo-atlas/modelos/IMeta';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { Metas } from 'src/integracoes/servicos/mongo-atlas/funcoes/metas';

interface FetchListaMetasResult {
  isError: boolean;
  fator: IMeta[] | null;
  errorMessage: string | null;
}

interface FetchMetaResult {
  isError: boolean;
  campanha: IMeta | null;
  errorMessage: string | null;
}

// --------------------LISTAR METAS POR EMPRESA -----------------------------------------

const listarMetasEmpresa = async ({ mUser, empId }: { mUser: any, empId: string }) => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemMetas(mUser.user, empId);
    if (status) {
      return {
        isError: !status,
        metas: dados,
        errorMessage: mensagem
      };
    }
    return {
      isError: true,
      metas: null,
      errorMessage: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchMetasEmpresa({ payload }: { payload: any, type: any }) {
  const { mUser, empId } = payload;
  try {
    const metaData: FetchListaMetasResult = yield call(listarMetasEmpresa, { mUser, empId });
    if (!metaData.isError) {
      yield put(metaListMetasSuccess(metaData));
    } else {
      yield put(metaErrorMsg(metaData.errorMessage));
    }
  } catch (error: any) {
    yield put(metaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchMetas() {
  yield takeEvery(META_LIST_METAS_INIT, parseFetchMetasEmpresa);
}

// -------------------- OBETER META POR ID-----------------------------------------

const obterMeta = async ({ mUser, id }: { mUser: any, id: string }) => {
  try {
    const { status, dados, mensagem } = await Metas.obtem(mUser.user, id);
    if (status) {
      return {
        isError: !status,
        meta: dados as IMeta,
        errorMessage: mensagem
      };
    }
    return {
      isError: !status,
      meta: null,
      errorMessage: mensagem
    };

  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchMeta({ payload }: { payload: any, type: any }) {
  const { mUser, id } = payload;
  try {
    const metaData: FetchMetaResult = yield call(obterMeta, { mUser, id });
    if (!metaData.isError) {
      yield put(obtemMetaSuccess(metaData));
    } else {
      yield put(metaErrorMsg({ msg: metaData.errorMessage }));
    }
  } catch (error: any) {
    yield put(metaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchMeta() {
  yield takeEvery(META_OBTEM_META_INIT, parseFetchMeta);
}

// ---------------------------Criar Meta---------------------------------------------------------

const createMetaAsync = async ({ mUser, meta }:
  { mUser: any, meta: IMeta }
) => {
  try {
    const { status, dados, mensagem } = await Metas.cria(mUser.user, meta);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createMetaMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, meta } = payload;
  try {
    const { isCreated, msg, data } = yield call(createMetaAsync, { mUser, meta });
    if (isCreated) {
      yield put(createMetasSucess({ current: data, msg }));
    } else {
      yield put(metaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(metaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateMeta() {
  yield takeEvery(CREATE_METAS_INIT, createMetaMiddle);
}

// ---------------------------Eitar Meta---------------------------------------------------------

const atualizaMetaAsync = async ({ mUser, meta }:
  { mUser: any, meta: IMeta }
) => {
  try {
    const { status, dados, mensagem } = await Metas.atualiza(mUser.user, meta);
    if (status) {
      return {
        isUpdated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isUpdated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* atualizaMetaMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, meta } = payload;
  try {
    const { isUpdated, msg, data } = yield call(atualizaMetaAsync, { mUser, meta });
    if (isUpdated) {
      yield put(updateMetaSuccess({ current: data, msg }));
    } else {
      yield put(metaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(metaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtualizaMeta() {
  yield takeEvery(UPDATE_META_INIT, atualizaMetaMiddle);
}

// ---------------------------Deletar Meta---------------------------------------------------------

const deleteMetaAsync = async ({ mUser, id }:
  { mUser: any, id: string }
) => {
  try {
    const { status, dados, mensagem } = await Metas.deleta(mUser.user, id);
    if (status) {
      return {
        isDeleted: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isDeleted: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* deleteMetaMiddle({ payload }: { payload: any, type: any }) {
  const { mUser, id } = payload;
  try {
    const { isDeleted, msg, data } = yield call(deleteMetaAsync, { mUser, id });
    if (isDeleted) {
      yield put(metaDeleteMetaSuccess({ current: data, msg }));
    } else {
      yield put(metaErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(metaErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDeleteMeta() {
  yield takeEvery(META_DELETE_META_INIT, deleteMetaMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchFetchMetas),
    fork(watchFetchMeta),
    fork(watchCreateMeta),
    fork(watchAtualizaMeta),
    fork(watchDeleteMeta)
  ]);
}
