/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Collapse
} from '@material-ui/core';
import Logo from '../../components/Logo';
import NavItem from './NavItem';
import { PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPELDEFAULT } from '../../utils/rbac/papeis';
import HasAccessItemsMenu from '../../utils/rbac/componentsByRole';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { listMenuUrl } from '../../utils/functions';
import { isNil } from 'lodash';
import { sectionsMenu } from 'src/constants';
import { setEmpresa } from 'src/redux/actions';
import AdminEmpSelection from 'src/components/AdminEmpSelection';
import { versaoFrontend } from 'src/config';

function renderNavItems({ items, pathname, depth = 0, myPrivilege }) {
  const filteredItems = HasAccessItemsMenu({ items, myPrivilege });
  return (
    <List disablePadding>
      {filteredItems.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, myPrivilege }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, myPrivilege }) {
  const { title, href, icon, info, items } = item;
  const key = `${title}-${depth}-${href || ''}`;

  const isRelatorio = title === 'Relatórios';

  const open = isRelatorio || matchPath(pathname, {
    path: href,
    exact: false
  });

  const navItemProps = {
    depth,
    icon,
    info,
    key,
    open: Boolean(open),
    title,
    svgIcon: Boolean(false)
  };
  

  const renderedNavItem = items
    ? (
      <NavItem {...navItemProps}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items,
          myPrivilege
        })}
      </NavItem>
    )
    : (
      <NavItem
        depth={depth}
        href={href}
        icon={item.icon}
        info={info}
        key={key}
        title={title}
        svgIcon={item.svgIcon}
      />
    );

  acc.push(renderedNavItem);
  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 55,
    height: 'calc(100% - 55px)',
    borderRight: '2px solid rgba(0, 0, 0, 0.0)',
    '&::-paperAnchorDockedLeft': {
      borderRight: '5px solid #ffffff'
    },
    '&::-webkit-scrollbar': {
      height: '6px',
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#DFE9EB',
      border: '10px solid #FFFFFF',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#B8C0C2',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(154,154,154, 0.5)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(154,154,154, 0.7)',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: 'rgba(154,154,154, 0.9)',
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  iconOpenClose: {
    marginTop: 12,
    float: 'right',
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    letterSpacing: 0,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%'
  },
  rodape: {
    paddingTop: theme.spacing(2)
  },
  subHeader: {
    float: 'left'
  },
  subHeaderFont: {
    color: theme.palette.text.secondary
  },
  active: {
    color: `${theme.palette.primary.main}!important`
  },
  containerNav: {
    display: 'flex',
    flexDirection: 'column'
  },
  topoNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 10px'
  },
  footerNav: {
    flex: 0,
    flexDirection: 'column-reverse',
    paddingTop: 12
  },
  lista: {
    padding: '0 8px'
  },
  subheader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    flex: 1,
    height: '1px',
    backgroundColor: theme.palette.divider,
    marginLeft: '16px',
    position: 'relative',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const isMountedRef = useIsMountedRef();

  const {
    myPrivilege,
    empId,
    myEmp,
    myDashs,
    tiposDash,
    categoriasDash
  } = useSelector(
    (state) => ({
      myEmp: state.account.myEmp,
      myPrivilege: String(state.account.user?.privilegeHash || PAPELDEFAULT),
      empId: state.account.myEmp.id,
      empName: state.account.myEmp.name,
      myDashs: state.dashs.dashs,
      tiposDash: state.dashs.tiposDash,
      categoriasDash: state.categorias.categorias,
    }),
    shallowEqual
  );

  const userPermission = myPrivilege; // Defina as permissões do usuário
  const [sections, setSection] = useState([]);

  const dispatch = useDispatch();
  const handleEmp = (emp) => {
    dispatch(setEmpresa({ empresa: emp }));
    return true;
  };

  const hasSectionPermission = (sectionPermissions, userPermissions) => {
    if (!sectionPermissions) {
      // Se a seção não especifica permissões, ela é acessível para todos
      return true;
    }
    // Verifique se o usuário possui pelo menos uma das permissões da seção
    return sectionPermissions.some((permission) => userPermissions.includes(permission));
  };

  const filteredSections = sectionsMenu.filter((section) => {
    return hasSectionPermission(section.allowedRoles, userPermission);
  });

  // Função para verificar se o item "Relatorios" deve ser ocultado
  const ocultarItemRelatorio = (privilege, dashs) => {
    const dashboards = dashs.length === 0;
    const ocultaItem = privilege === PAPEL1 || privilege == PAPEL2;

    return ocultaItem && dashboards;
  }

  // Oculta o item "Relatorios" se necessário
  if (ocultarItemRelatorio(myPrivilege, myDashs)) {
    filteredSections.shift();
  }

  useEffect(() => {
    const sectionsloader = () => {
      if (isMountedRef.current && !isNil(categoriasDash) && !isNil(tiposDash) && !isNil(myDashs)) {
        // Mapeia as categorias de dashboards
        const fullDash = categoriasDash
          .map((categ) => {
            const cateDash = myDashs
              .map((dash) => {
                if (categ.id === dash.categoriaId) {
                  const myType = tiposDash.find((tipo) => tipo.id === dash.tipoId);
                  return {
                    id: dash.id,
                    name: dash.name, // Nome do dashboard
                    icon: myType?.icon || null, // Ícone se existir
                    url: dash.url // URL do dashboard
                  };
                }
                return null;
              })
              .filter((dash) => dash !== null);

            if (cateDash.length > 0) {
              return {
                categoria: categ.name, // Nome da categoria
                categoriaId: categ.id,  // ID da categoria
                dashs: cateDash // Dashboards da categoria
              };
            }
            return null;
          })
          .filter((dash) => dash !== null);

        // Chama a função para criar a estrutura do menu
        const lista = listMenuUrl({
          baseUrl: 'dashboard',
          itens: fullDash,
          empId,
          subHeaderName: 'categoria',
          subHeaderIdName: 'categoriaId',
          itemName: 'dashs',
          subItemName: 'id',
          papeis: [PAPEL1, PAPEL2]
        });

        // Atualiza o sections com a lista gerada
        setSection(lista);
      }
    };

    sectionsloader();
  }, [categoriasDash, empId, isMountedRef, myDashs, tiposDash]);

  // Atualiza o items do primeiro elemento do sectionsMenu com os dashboards
  sectionsMenu[0].items = sections;


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <AdminEmpSelection handleEmp={handleEmp} />
        <Box>
          {filteredSections.map((section, index) => (
            <React.Fragment key={section.subheader || index}>
              <List
                disablePadding
                className={classes.lista}
                subheader={
                  <ListSubheader disableGutters disableSticky className={classes.subheader}>
                    {section.subheader}
                    {/* Condicional para adicionar a linha somente se section.subheader existir */}
                    {section.subheader && <div className={classes.line}></div>}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  myPrivilege: myPrivilege
                })}
              </List>
              {/* {section.subheader === 'Relatórios' && myPrivilege === PAPEL2 && (
                <Divider />
              )} */}
            </React.Fragment>
          ))
          }
        </Box>
        <Divider />
        <Box className={classes.footerNav} display="flex">
          <Typography
            align="center"
            display="inline"
            variant="body2"
            color="textSecondary"
          >
            Versão {versaoFrontend}-{userPermission}
          </Typography>
        </Box>
      </>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;