import { User as RealmUser } from 'realm-web';
import { IFator } from '../modelos/IFator';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { FarmaError } from 'src/integracoes/modelos/erros';

// ------------------------ OBTER FATOR ------------------------ //
const obtem = async (
    adminMongoDB: RealmUser,
    fator: IFator
  ): Promise<IRespostaFuncao<IFator>> => {
    return adminMongoDB.functions['empresas/lojas/fatores/obtemFator'](fator)
      .then((resultado) => resultado as IRespostaFuncao<IFator>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao obter Fator de Correção',
          causa: e
        };
        throw new FarmaError(erro);
    });
};
  
// ------------------------ CRIAR FATOR ------------------------ //
const cria = async (
    adminMongoDB: RealmUser,
    fator: IFator
  ): Promise<IRespostaFuncao<IFator>> => {
    return adminMongoDB.functions['empresas/lojas/fatores/criaFator'](fator)
      .then((resultado) => resultado as IRespostaFuncao<IFator>)
      .catch((e: any) => {
        const erro = {
          nome: 'MONGODB-ERRO-DESCONHECIDO',
          mensagem: 'Erro ao criar Fator de Correção',
          causa: e
        };
        throw new FarmaError(erro);
      });
  };
  
// ------------------------ ATUALIZAR FATOR ------------------------ //
const atualiza = async (
    adminMongoDB: RealmUser,
    fator: IFator
): Promise<IRespostaFuncao<IFator>> => {
    return adminMongoDB.functions['empresas/lojas/fatores/atualizaFator'](fator)
        .then((resultado) => resultado as IRespostaFuncao<IFator>)
        .catch((e: any) => {
        const erro = {  
            nome: 'MONGODB-ERRO-DESCONHECIDO',
            mensagem: 'Erro ao atualizar dados Fator de Correção',
            causa: e
        };
        throw new FarmaError(erro);
    });
};

export const Fatores = {
    obtem,
    cria,
    atualiza
};
  