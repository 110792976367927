import {
  CREATE_FATOR_INIT,
  CREATE_FATOR_SUCCESS,
  FATOR_ERROR_MSG,
  CLEAR_FATOR,
  UPDATE_FATOR_INIT,
  UPDATE_FATOR_SUCCESS,
  FATOR_LIST_FATORES_EMPRESA_INIT,
  FATOR_LIST_FATORES_EMPRESA_SUCCESS,
  FATOR_OBTEM_FATOR_INIT,
  FATOR_OBTEM_FATOR_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  users: [],
  fator: [],
  isUpdated: false,
  isCreated: false,
  isError: false,
  current: null,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FATOR_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case CREATE_FATOR_INIT:
      return { ...state, loading: true, isCreated: false };
    case UPDATE_FATOR_INIT:
      return { ...state, loading: false, isUpdated: false };
    case UPDATE_FATOR_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isUpdated: true, loading: false, msg };
    }
    case CREATE_FATOR_SUCCESS: {
      const { current, msg } = action.payload;
      return { ...state, current, isCreated: true, loading: false, msg };
    }
    case FATOR_LIST_FATORES_EMPRESA_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case FATOR_LIST_FATORES_EMPRESA_SUCCESS: {
      const { fator } = action.payload;
      return {
        ...state,
        fator: fator,
        loading: false,
        error: null
      };
    }
    case CLEAR_FATOR: {
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isError: false,
        current: null,
        loading: false
      };
    }
    case FATOR_OBTEM_FATOR_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case FATOR_OBTEM_FATOR_SUCCESS: {
      const { fator } = action.payload;
      return {
        ...state,
        fator: fator,
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};
