import {
  CREATE_CAMPANHA_INIT,
  CREATE_CAMPANHA_SUCCESS,
  CAMPANHA_ERROR_MSG,
  CLEAR_CAMPANHA,
  UPDATE_CAMPANHA_INIT,
  UPDATE_CAMPANHA_SUCCESS,
  DUPLICATE_CAMPANHA_INIT,
  DUPLICATE_CAMPANHA_SUCCESS,
  CAMPANHA_LIST_CAMPANHA_INIT,
  CAMPANHA_LIST_CAMPANHA_SUCCESS,
  CAMPANHA_DELETE_CAMPANHA_INIT,
  CAMPANHA_DELETE_CAMPANHA_SUCCESS,
  CAMPANHA_OBTEM_CAMPANHA_INIT,
  CAMPANHA_OBTEM_CAMPANHA_SUCCESS
} from '../actionTypes';

export const obtemCampanhasListInit = (data) => ({
  type: CAMPANHA_LIST_CAMPANHA_INIT,
  payload: data
});

export const obtemCampanhasListSuccess = (data) => ({
  type: CAMPANHA_LIST_CAMPANHA_SUCCESS,
  payload: data
});

export const obtemCampanhaInit = (data) => ({
  type: CAMPANHA_OBTEM_CAMPANHA_INIT,
  payload: data
});

export const obtemCampanhaSuccess = (data) => ({
  type: CAMPANHA_OBTEM_CAMPANHA_SUCCESS,
  payload: data
});

export const createCampanhaInit = (data) => ({
  type: CREATE_CAMPANHA_INIT,
  payload: data
});

export const createCampanhaSucess = ({ current, msg }) => {
  return {
    type: CREATE_CAMPANHA_SUCCESS,
    payload: { current, msg }
  };
};

export const updateCampanhaInit = (data) => ({
  type: UPDATE_CAMPANHA_INIT,
  payload: data
});

export const updateCampanhaSuccess = ({ msg }) => {
  return {
    type: UPDATE_CAMPANHA_SUCCESS,
    payload: { msg }
  };
};

export const campanhaDeleteCampanhaInit = (data) => ({
  type: CAMPANHA_DELETE_CAMPANHA_INIT,
  payload: data
});

export const campanhaDeleteCampanhaSuccess = (data) => ({
  type: CAMPANHA_DELETE_CAMPANHA_SUCCESS,
  payload: data
});

export const duplicateCampanhaInit = (data) => ({
  type: DUPLICATE_CAMPANHA_INIT,
  payload: data
});

export const duplicateCampanhaSuccess = ({ msg }) => {
  return {
    type: DUPLICATE_CAMPANHA_SUCCESS,
    payload: { msg }
  };
};

export const campanhaErrorMsg = (data) => ({
  type: CAMPANHA_ERROR_MSG,
  payload: data
});

export const clearCampanha = () => ({
  type: CLEAR_CAMPANHA,
  payload: {}
});