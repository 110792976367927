import {
  CREATE_METAS_INIT,
  CREATE_METAS_SUCCESS,
  METAS_ERROR_MSG,
  CLEAR_METAS,
  UPDATE_META_INIT,
  UPDATE_META_SUCCESS,
  META_LIST_METAS_INIT,
  META_LIST_METAS_SUCCESS,
  META_DELETE_META_INIT,
  META_DELETE_META_SUCCESS,
  META_OBTEM_META_INIT,
  META_OBTEM_META_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  metas: [],
  meta: {},
  isUpdated: false,
  isCreated: false,
  isDeleted: false,
  isError: false,
  current: null,
  isRecived: false,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case METAS_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg, isError: true };
    }
    case CREATE_METAS_INIT: {
      return { ...state, loading: true, isCreated: false, isError: false };
    }
    case CREATE_METAS_SUCCESS: {
      const { current, msg } = action.payload;
      return { ...state, current, isCreated: true, loading: false, msg };
    }
    case UPDATE_META_INIT:
      return { ...state, loading: false, isUpdated: false };
    case UPDATE_META_SUCCESS: {
      const { msg } = action.payload;
      return { ...state, isUpdated: true, loading: false, msg };
    }
    case META_LIST_METAS_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case META_LIST_METAS_SUCCESS: {
      const { metas } = action.payload;
      return {
        ...state,
        metas,
        loading: false
      };
    }
    case META_DELETE_META_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case META_DELETE_META_SUCCESS: {
      const { id, msg } = action.payload;
      return {
        ...state,
        metas: state.metas.filter((elem) => elem.id !== id),
        loading: false,
        isDeleted: true,
        msg
      };
    }
    case CLEAR_METAS: {
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isError: false,
        current: null,
        loading: false,
        isRecived: false
      };
    }
    case META_OBTEM_META_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case META_OBTEM_META_SUCCESS: {
      const { meta } = action.payload;
      return {
        ...state,
        meta: meta,
        loading: false,
        isRecived: true,
        isError: false
      };
    }
    default:
      return { ...state };
  }
};
