// componentsByrequiredPrivileges(
//          userPrivileges: userPrivileges,
//          module: "metas",
//          privileges: ["read", "update"]
// )

// Testa se o usuário logado possui os privilégios
// requeridos

// Recebe três variáveis especificando o
// módulo a que se referem os privilégios,
// os privilégios requeridos e objeto com
// os privilégios do papel do usuário logado

// Retorna true caso o usuário
// logado tenha os privilégios requeridos

// Retorna false caso o usuário logado não possua
// os privilégios requeridos

import { isNil } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/redux/types';

interface useComponentsByRequiredPrivilegesProps {
  module: string;
  privileges: string[];
}

const useComponentsByRequiredPrivileges = ({
  module,
  privileges
}: useComponentsByRequiredPrivilegesProps): boolean => {
  const { userPrivileges } = useSelector(
    (state: RootState) => ({
      userPrivileges: state.account.userPrivileges
    }),
    shallowEqual
  );
  let permission = true;
  privileges.forEach((privilege: string) => {
    if (!isNil(userPrivileges[module])) {
      permission &&= userPrivileges[module][privilege];
    } else {
      permission = false;
    }
  });

  return permission;
};

export default useComponentsByRequiredPrivileges;
