import {
  LIST_CATEGORIA_INIT,
  LIST_CATEGORIA_SUCCESS,
  CREATE_CATEGORIA_INIT,
  CREATE_CATEGORIA_SUCCESS,
  UPDATE_CATEGORIA_INIT,
  UPDATE_CATEGORIA_SUCCESS,
  DELETE_CATEGORIA_INIT,
  DELETE_CATEGORIA_SUCCESS,
  CATEGORIA_ERROR_MSG,
  CLEAR_CATEGORIA
} from '../actionTypes';

export const listCategoriaPainelInit = (data) => ({
  type: LIST_CATEGORIA_INIT,
  payload: data
});

export const listCategoriaPainelSuccess = (categorias) => {
  return {
    type: LIST_CATEGORIA_SUCCESS,
    payload: { categorias }
  };
};

export const createCategoriaPainelInit = (data) => ({
  type: CREATE_CATEGORIA_INIT,
  payload: data
});

export const createCategoriaPainelSuccess = (data) => ({
  type: CREATE_CATEGORIA_SUCCESS,
  payload: data
});

export const updateCategoriaPainelInit = (data) => ({
  type: UPDATE_CATEGORIA_INIT,
  payload: data
});

export const updateCategoriaPainelSuccess = (data) => ({
  type: UPDATE_CATEGORIA_SUCCESS,
  payload: data
});

export const deleteCategoriaPainelInit = (data) => ({
  type: DELETE_CATEGORIA_INIT,
  payload: data
});

export const deleteCategoriaPainelSuccess = (data) => ({
  type: DELETE_CATEGORIA_SUCCESS,
  payload: data
});

export const categoriaErrorMsg = (data) => ({
  type: CATEGORIA_ERROR_MSG,
  payload: data
});

export const clearCategoria = (data) => ({
  type: CLEAR_CATEGORIA,
  payload: data
});

