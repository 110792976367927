import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { User as RealmUser } from 'realm-web';
import { IUsuarioFormulario } from 'src/integracoes/modelos/usuarios';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { Usuarios } from 'src/integracoes/servicos/mongo-atlas/funcoes/usuarios';
import {
  criaUsuarioFirebase,
  removeUsuarioFirebase,
  enviaEmailAtivacao,
  atualizaUsuarioFirebase
} from '../../integracoes/servicos/firebase/functions';
import {
  USERS_FETCH_DATA_INIT,
  USERS_CREATE_USER_INIT,
  USERS_DELETE_USER_INIT,
  USERS_MODIFY_USER_INIT,
  ATRIBUIR_EMPRESA_INIT
} from '../actionTypes';
import {
  usersFetchDataSuccess,
  usersCreateUserSuccess,
  usersDeleteUserSuccess,
  usersModifyUserSuccess,
  atribuirEmpresaSuccess,
  userErrorMsg
} from './actions';
import { IUsuarioMongoDB } from 'src/integracoes/modelos/usuarios';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';

interface FetchListaUsuariosResult {
  isError: boolean;
  usuarios: IUsuarioMongoDB[] | null;
  msg: string | null;
}

// ---------------Busca lista Usuários por empresa --------------------------------------------

const fetchListarUsuarios = async ({ mUser, empId }: { mUser: any, empId: string }): Promise<FetchListaUsuariosResult> => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemUsuarios(mUser.user, empId);
    if (Array.isArray(dados)) {
      return {
        isError: !status,
        usuarios: dados,
        msg: mensagem
      };
    }
    return {
      isError: true,
      usuarios: [],
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

export function* parseFetchListaUsuarios({ payload }: { payload: any, type: any }) {
  const { mUser, empId } = payload;
  try {
    const usersData: FetchListaUsuariosResult = yield call(fetchListarUsuarios, { empId, mUser });
    if (!usersData.isError) {
      yield put(usersFetchDataSuccess(usersData));
    } else {
      yield put(userErrorMsg({ msg: usersData.msg }));
    }
  } catch (error: any) {
    yield put(userErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchFetchListaUsuarios() {
  yield takeEvery(USERS_FETCH_DATA_INIT, parseFetchListaUsuarios);
}


// ----------------Cria um Usuário-------------------------------------------

export const criarUsuario = async (dados: {
  usuarioFormulario: IUsuarioFormulario;
  adminMongoDB: RealmUser;
}): Promise<{
  msg: string;
  user: any;
}> => {
  const { usuarioFormulario, adminMongoDB } = dados;
  try {
    const {
      dados: usuarioRetornoFirebase
    }: any | unknown = await criaUsuarioFirebase(usuarioFormulario);
    usuarioFormulario.id = usuarioRetornoFirebase.uid;
    try {
      await Usuarios.cria(adminMongoDB, usuarioFormulario);
    } catch (e: any) {
      await removeUsuarioFirebase(usuarioFormulario);
      throw new FarmaError(e);
    }
    try {
      await enviaEmailAtivacao(usuarioFormulario);
    } catch (e: any) {
      throw new FarmaError(e);
    }
    const user = { id: usuarioRetornoFirebase.uid };
    return {
      msg:
        'Usuário criado com sucesso. Um e-mail de ativação foi enviado ao novo usuário',
      user: user
    };
  } catch (e: any) {
    throw new FarmaError(e);
  }
};

function* parseCriaUsuario({ payload }: { payload: any; type: string }) {
  const usuarioFormulario: IUsuarioFormulario = payload.data;
  const adminMongoDB: RealmUser = payload.mongoUser;
  const dados = { usuarioFormulario, adminMongoDB };
  try {
    const { msg, user } = yield call(criarUsuario, dados);
    yield put(
      usersCreateUserSuccess({
        user,
        msg: msg
      })
    );
  } catch (e: any) {
    yield put(userErrorMsg({ msg: e.mensagem }));
  }
}

export function* watchCreateUser() {
  yield takeEvery(USERS_CREATE_USER_INIT, parseCriaUsuario);
}

// ---------------------Atualizar Usuário--------------------------------------------------

export const atualizarUsuario = async (dados: {
  usuarioNovosDados: IUsuarioFormulario;
  usuarioDadosOriginais: IUsuarioFormulario;
  adminMongoDB: RealmUser;
}): Promise<{
  msg: string;
}> => {
  const { usuarioNovosDados, usuarioDadosOriginais, adminMongoDB } = dados;
  try {
    // Atualizando dados do usuario no Firebase Auth
    const { mensagem, dados }: any | unknown = await atualizaUsuarioFirebase(
      usuarioNovosDados
    );
    // Atualizando dados do usuario no MongoDB
    try {
      const { mensagem: msgMongo }: any = await Usuarios.atualiza(
        adminMongoDB,
        usuarioNovosDados
      );
    } catch (e: any) {
      // ocorreu um erro ao atualizar dados do usuario no MONGODB
      // desfazer alteracoes que foram feitas no FIREBASE
      await atualizaUsuarioFirebase(usuarioDadosOriginais);
      throw new FarmaError(e);
    }
    return { msg: 'Usuário atualizado com sucesso' };
  } catch (e: any) {
    throw new FarmaError(e);
  }
};

function* parseAtualizaUsuario({ payload }: { payload: any; type: string }) {
  const usuarioNovosDados: IUsuarioFormulario = payload.newData;
  const usuarioDadosOriginais: IUsuarioFormulario = payload.oldData;
  const adminMongoDB: RealmUser = payload.mongoUser;
  const dados = { usuarioNovosDados, usuarioDadosOriginais, adminMongoDB };
  try {
    const { msg } = yield call(atualizarUsuario, dados);
    yield put(
      usersModifyUserSuccess({
        msg: msg
      })
    );
  } catch (e: any) {
    yield put(userErrorMsg({ msg: e.mensagem }));
  }
}
export function* watchAtualizaUsuario() {
  yield takeEvery(USERS_MODIFY_USER_INIT, parseAtualizaUsuario);
}

// ----------------Deleta Usuário------------------------------------------
export const deletaUsuario = async (dados: {
  usuarioFormulario: IUsuarioFormulario;
  adminMongoDB: RealmUser;
}): Promise<{
  msg: string;
}> => {
  const { usuarioFormulario, adminMongoDB } = dados;
  try {
    // chamando funcao para desativar usuario no MongoDB
    const { mensagem: msgMongo }: any = await Usuarios.deleta(
      adminMongoDB,
      usuarioFormulario
    );
    // chamando funcao para remover usuario do Firebse Auth
    const { mensagem }: any | unknown = await removeUsuarioFirebase(
      usuarioFormulario
    );
    return { msg: msgMongo };
  } catch (e: any) {
    throw new FarmaError(e);
  }
};

function* parseDeletaUsuario({ payload }: { payload: any; type: string }) {
  const usuarioFormulario: IUsuarioFormulario = payload.data;
  const adminMongoDB: RealmUser = payload.mongoUser;
  const dados = { usuarioFormulario, adminMongoDB };
  try {
    const { msg } = yield call(deletaUsuario, dados);
    yield put(usersDeleteUserSuccess({ id: usuarioFormulario.id, msg }));
  } catch (e: any) {
    yield put(userErrorMsg({ msg: e.mensagem }));
  }
}

export function* watchDeletaUsuario() {
  yield takeEvery(USERS_DELETE_USER_INIT, parseDeletaUsuario);
}

// ---------------------Atribuir empresas Usuário--------------------------------------------------
const atribuiEmpresasUsuarioAsync = async ({ mUser, usuario }: {
  mUser: any,
  usuario: IUsuarioFormulario
}) => {
  try {
    const resposta = await Usuarios.atribuiEmpresas(
      mUser.user,
      usuario
    );
    if (resposta.status) {
      return { isUpdated: true, msg: resposta.mensagem };
    } else {
      return {
        isUpdated: false,
        msg: resposta.mensagem
      };
    }
  }
  catch (error: any) {

    throw new FarmaError(error);
  }
};

function* parseAtribuiEmpresasUsuario({ payload }: { payload: any, type: any }) {
  const {
    mUser,
    usuario
  } = payload;
  try {
    const { isUpdated, msg } = yield call(atribuiEmpresasUsuarioAsync, {
      mUser,
      usuario
    });
    if (isUpdated) {
      yield put(atribuirEmpresaSuccess({ msg }));
    } else {
      yield put(userErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(userErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtribuEmpresasUsuario() {
  yield takeEvery(ATRIBUIR_EMPRESA_INIT, parseAtribuiEmpresasUsuario);
}

//-----------------------------------------------------------------------
export default function* rootSaga() {
  yield all([
    fork(watchFetchListaUsuarios),
    fork(watchCreateUser),
    fork(watchDeletaUsuario),
    fork(watchAtualizaUsuario),
    fork(watchAtribuEmpresasUsuario)
  ]);
}
