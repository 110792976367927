import {
  AUTH_STATE_CHANGED,
  USER_DATA_INIT,
  CLEAR_USER_AUTH,
  GET_EMPRESA_INIT,
  GET_EMPRESA_SUCCESS,
  SET_EMPRESA,
  AUTH_ERROR_MSG
} from '../actionTypes';

export const authStateChanged = (data) => ({
  type: AUTH_STATE_CHANGED,
  payload: data
});

export const userDataRequestInit = (data) => ({
  type: USER_DATA_INIT,
  payload: data
});

export const clearUserAuth = (data) => ({
  type: CLEAR_USER_AUTH,
  payload: data
});

export const getEmpresaInit = ({ mUser, empId }) => ({
  type: GET_EMPRESA_INIT,
  payload: { mUser, empId }
});

export const getEmpresaSuccess = ({ myEmp }) => ({
  type: GET_EMPRESA_SUCCESS,
  payload: { myEmp }
});

export const setEmpresa = ({ empresa }) => ({
  type: SET_EMPRESA,
  payload: { empresa }
});

export const authErrorMsg = (data) => ({
  type: AUTH_ERROR_MSG,
  payload: data
});
